import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ 'layerDiv' ];

  push = () => {
    const eventName = this.layerDivTarget.dataset.eventName;

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': eventName
    })
  }
}
