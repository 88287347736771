import { Controller } from '@hotwired/stimulus';
import { agencyFees } from '../../shared/helpers/agencyFeesHelper';

export default class extends Controller {
  static targets = ['estimationStep',
                    'resultStep',
                    'submit',
                    'agencyFees',
                    'netSellerWithClassicAgency',
                    'netSellerWithHosman',
                    'agencyFeesDiff',
                    'premiumLogo',
                    'priceRef',
                    'firstCategory',
                    'secondCategory',
                    'thirdCategory',
                    'fourthCategory',
                    'input',
                    'category',
                    'yourCommission',
                    'changeCriteria'];

  connect = () => {
    this.parisRegion = null;
    this.houseKind = null;
    this.price = null;
    this.noExclusivity = false;
    this.region = null;
    if (this.hasSubmitTarget && !this.submitTarget.disabled) this.submitTarget.disabled = true
  }

  handleChange = (e) => {
    const target = e.currentTarget

    if (target.name === 'region') {
      this.parisRegion = target.value.toLowerCase() === 'paris'
      this.region = target.value.toLowerCase()
    }

    if (target.name === 'price') this.price = target.value

    if (target.dataset?.propertyKind !== undefined) this.houseKind = target.dataset.propertyKind === 'house'

    if (this.parisRegion !== null && this.houseKind !== null && this.price !== null) this.submitTarget.disabled = false
  }

  handleSubmit = () => {
    this.handleStepsDisplay()
    this.handleAgencyFees()
    this.handleHTMLContent()
    this.handleHTMLDisplay()
    this.pushDataLayer()
  }

  resetModule = () => {
    if(!this.premiumLogoTarget.classList.contains('vesta-display-none')) this.premiumLogoTarget.classList.add('vesta-display-none')
    this.estimationStepTarget.classList.remove('vesta-display-none')
    this.resultStepTarget.classList.remove('active')
    this.categoryTargets.map(category => {
      if(!category.classList.contains('vesta-display-none')) category.classList.add('vesta-display-none')
    })
    this.inputTargets.map(input => {
      if(input.name === 'propertyKind') input.checked = false
      else input.value = ''
    })
    this.resetSlimSelect()
    this.connect()
  }

  resetSlimSelect = () => {
    const inputs = this.inputTargets.filter(input => input.type === 'select-one')
    inputs.forEach(input => {
      const slimSelectController = this.application.getControllerForElementAndIdentifier(
        input,
        "application--slim-select"
      );
      slimSelectController.destroy()
      slimSelectController.connect()
    })
  }

  handleStepsDisplay = () => {
    this.estimationStepTarget.classList.add('vesta-display-none')
    this.resultStepTarget.classList.add('active')
  }

  handleAgencyFees = () => {
    this.agencyFees = agencyFees(
      this.parisRegion,
      this.houseKind,
      this.price,
      this.noExclusivity
    );
  }

  handleHTMLContent = () => {
    this.feesWithClassicAgency = Number(this.price) * (4.87 / 100)
    this.agencyFeesTarget.innerHTML = Number(this.price) > 2_000_000 ? '2%' : this.formatPriceHelper(this.agencyFees)
    this.netSellerWithHosmanTarget.innerHTML = this.formatPriceHelper(Number(this.price) - Number(this.agencyFees))
    this.netSellerWithClassicAgencyTarget.innerHTML = this.formatPriceHelper(Number(this.price) - Number(this.feesWithClassicAgency))
    this.agencyFeesDiffTarget.innerHTML = this.formatPriceHelper(this.feesWithClassicAgency - this.agencyFees)
    this.priceRefTarget.innerHTML = this.formatPriceHelper(Number(this.price))
    if(Number(this.price) > 2_000_000) this.yourCommissionTarget.innerHTML = 'Votre prix';
    if(Number(this.price) > 2_000_000) this.changeCriteriaTarget.innerHTML = 'Recalculer mon prix';
  }

  handleHTMLDisplay = () => {
    // Premium logo condition
    if ((Number(this.price) >= 800000 && !this.parisRegion) || (Number(this.price) >= 900000 && this.parisRegion)) this.premiumLogoTarget.classList.toggle('vesta-display-none')

    // "Strong points" trad condition according to hosman prices steps
    if (Number(this.price) <= 300000) this.firstCategoryTarget.classList.toggle('vesta-display-none')
    else if (Number(this.price) <= 500000) this.secondCategoryTarget.classList.toggle('vesta-display-none')
    else if (Number(this.price) <= 900000) this.thirdCategoryTarget.classList.toggle('vesta-display-none')
    else this.fourthCategoryTarget.classList.toggle('vesta-display-none')
  }

  pushDataLayer = () => {
    // new GA4 tracking plan
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'submit_tariff_estimation',
      'region': this.region,
      'property_kind': this.houseKind ? 'house' : 'apartment'
    })
  }

  formatPriceHelper = (price) => {
    const options = { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 };
    const priceString = price.toLocaleString('fr-FR', options);
    return priceString;
  }

  scrollToAnchor() {
    const anchor = document.querySelector("#title-anchor")
    anchor.scrollIntoView({ behavior: 'smooth' })
  }
}
