import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'banners',
    'text',
    'errorBanner',
    'userInfoForm',
    'criteriasForm',
    'userInfoIllu',
    'criteriasIllu',
    'loadingLayerSizedAbsolute',
    'formContainer'
  ]


  connect() {
    // new GA4 tracking plan
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'view_buyer_alert_step_1'
    })

    //Disable render in the first part ot the form after render with an error.
    if (!window.location.pathname.includes('new')) {
      this.showStep2();
    }
  }

  handleToggle = () => {
    $('html, body').animate({ scrollTop: 0 }, 500);

    this.userInfoFormTarget.classList.toggle('vesta-invisible');
    this.userInfoIlluTarget.classList.toggle('vesta-invisible');

    this.criteriasFormTarget.classList.toggle('vesta-invisible');
    this.criteriasIlluTarget.classList.toggle('vesta-invisible');
  }

  showStep1() {
    // new GA4 tracking plan
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'view_buyer_alert_step_1'
    })

    this.handleToggle();
    this.bannersTarget.classList.add('vesta-display-none');
  }

  spin = () => {
    this.loadingLayerSizedAbsoluteTarget.style.display = '';
  }

  showStep2() {
    // new GA4 tracking plan
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'view_buyer_alert_step_2',
    })

    this.handleToggle();
  }

  showErrors() {
    if (this.errorBannerTarget.classList.contains('vesta-visible')) {
      this.errorBannerTarget.classList.remove('vesta-visible');
      this.errorBannerTarget.classList.add('vesta-invisible');
    }
  }

  fetchSimilarSales = async () => {
    let form = this.formContainerTarget;
    const formData = new FormData(form);

    // form data retun array of string with value ['0'] at first. And then when user select kind
    // the array will have another value '1' and will be the second child in the array.
    let apartment = formData.getAll('user[buyer_alerts_attributes][0][apartment]')[1] || '0';
    let house = formData.getAll('user[buyer_alerts_attributes][0][house]')[1] || '0';
    let minBudget = formData.get('user[buyer_alerts_attributes][0][min_budget]');
    let maxBudget = formData.get('user[buyer_alerts_attributes][0][max_budget]');
    let minArea = formData.get('user[buyer_alerts_attributes][0][min_area]');
    let floor = formData.get('user[buyer_alerts_attributes][0][floor]');
    let minFloorForElevator = formData.get('user[buyer_alerts_attributes][0][min_floor_for_elevator]');
    let renovation = formData.get('user[buyer_alerts_attributes][0][renovation]');
    let outDoorSpace = formData.get('user[buyer_alerts_attributes][0][outdoor_space]');
    let minRoomNumber = formData.get('user[buyer_alerts_attributes][0][min_room_number]');
    let minBedroomNumber = formData.get('user[buyer_alerts_attributes][0][min_bedroom_number]');
    // First element of the array is always empty string.
    let zonesUnfiltered = formData.getAll('user[buyer_alerts_attributes][0][zone][]');
    let zonesFiltered = zonesUnfiltered.filter((zone) => {
      return zone != '';
    });

    let buyerAlertField = [
      { 'name': 'Budget Maximum', 'value': maxBudget },
      { 'name': 'Étage du bien', 'value': floor },
      { 'name': 'Surface minimum', 'value': minArea },
      { 'name': 'Ascenseur', 'value': minFloorForElevator },
      { 'name': 'Nombre de pièce(s) minimum', 'value': minRoomNumber },
      { 'name': 'Nombre de chambre(s) minimum', 'value': minBedroomNumber },
      { 'name': 'Où recherchez vous ?', 'value': zonesFiltered },
    ]

    let infoMessage = '';
    let counterErrors = 0;
    for (let entry of buyerAlertField) {
      if (entry.value === null || entry.value === '') {
        infoMessage += entry.name + ', ';
        counterErrors+= counterErrors + 1;
      }

      if (entry.name === 'Où recherchez vous ?' && entry.value.length === 0) {
        infoMessage += entry.name + ', ';
        counterErrors += counterErrors + 1;
      }
    }

    if (apartment === '0' && house === '0') {
      if (infoMessage === '') {
        infoMessage = 'Merci de choisir maison et/ou appartement';
      } else {
        infoMessage = infoMessage + 'Choix maison et/ou appartement';
      }
      counterErrors += counterErrors + 1;
    }

    if (infoMessage !== '') {
      $('html, body').animate({ scrollTop: 0 }, 500);
      this.errorBannerTarget.classList.remove('vesta-invisible');
      this.errorBannerTarget.classList.add('vesta-visible');
      this.errorBannerTarget.textContent = '';
      let textVariable = '';
      let uselessCaracter = infoMessage.lastIndexOf(',');
      if (uselessCaracter && uselessCaracter >= 0) infoMessage = infoMessage.slice(0, uselessCaracter);
      if (counterErrors > 1) {
        textVariable = 'Les champs suivants sont obligatoires';
      }
      else {
        textVariable = 'Le champ suivant est obligatoire';
      }
      this.errorBannerTarget.insertAdjacentHTML('beforeend', `<img width='25' height='25' loading='lazy' class='icon--small vesta-margin-r-10' src='https://res.cloudinary.com/vesta-home/image/upload/v1659084514/assets/revamp/icons/wrong-circle.svg'><div><p class='typo-subtitle-medium'> ${textVariable} : ${infoMessage}</p></div>`);
    } else {
      const params = {
        zone: zonesFiltered,
        min_budget: minBudget,
        max_budget: maxBudget,
        min_area: minArea,
        outdoor_space: outDoorSpace,
        floor: floor,
        min_floor_for_elevator: minFloorForElevator,
        apartment: apartment,
        house: house,
        min_room_number: minRoomNumber,
        min_bedroom_number: minBedroomNumber,
        renovation: renovation
      };

      this.showErrors();

      const url = '/api/v1/buyer_alerts/properties';
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': document.getElementsByName('csrf-token')[0].getAttribute('content'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ buyer_alert_params: params }),
        credentials: 'same-origin'
      });

      this.showStep2();

      const exitBtn = document.querySelector('.buyer-alert-exit')
      exitBtn.classList.add('step-2')

      const data = await response.json();
      let text = '';
      this.bannersTarget.classList.remove('vesta-display-none');
      if (this.hasTextTarget) this.textTarget.remove();
      if (data.total > 0) {
        text = `Avec ces critères, vous recevrez en moyenne ${ data.total } bien${ data.total > 1 ? 's' : '' } par semaine`;
      }
      else {
        text = 'Vous recevrez les annonces directement dans votre boîte mail';
      }
      this.bannersTarget.innerHTML = '';
      this.bannersTarget.insertAdjacentHTML('beforeend',
        `
          <div id='info-buyer-alert' class='vesta-flex-row-center'>
            <img width='25' height='25' loading='lazy' alt='check' src='https://res.cloudinary.com/vesta-home/image/upload/v1651757324/assets/revamp/icons/check-green.svg' class='icon--small vesta-margin-r-10'>

            <div data-application--buyer-alert-form-target='text'
              <span class='typo-subtitle-medium'>${text}</span>
              <img width='20' height='20' loading='lazy' alt='bell' src='https://res.cloudinary.com/vesta-home/image/upload/v1649258695/assets/revamp/emojis/bell.svg'>
            </div>
          </div>

          <div class='vesta-title-form-container-mobile vesta-margin-b-20 vesta-text-center'>
            <h4 class='typo-h4'>
              Encore quelques informations pour <span class='vesta-blue-100'>finaliser votre alerte achat !</span>
              <img width='20' height='20' loading='lazy' alt='smiling eyes' src='https://res.cloudinary.com/vesta-home/image/upload/v1663924720/assets/revamp/joypixels/people/smiling-face-with-smiling-eyes.svg'>
            </h4>
          </div>
        `
      );
    }
  }
}
