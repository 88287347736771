import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['modal', 'backdrop', 'modalContainer'];

  show() {
    this.modalTarget.classList.add('is-active');
    this.backdropTarget.classList.add('is-active');
    this.modalContainerTarget.classList.add('is-active');
    setTimeout(() => {
      this.modalTarget.classList.add('show');
      this.backdropTarget.classList.add('show');
    }, 10);
  }

  hide() {
    this.modalTarget.classList.remove('show');
    this.backdropTarget.classList.remove('show');
    this.modalTarget.classList.remove('is-active');
    this.backdropTarget.classList.remove('is-active');
    this.modalContainerTarget.classList.remove('is-active');
  }
}
