import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["text"]

  connect() {
    this.element.addEventListener('mouseenter', () => {
      this.show()
    })
    this.element.addEventListener('mouseleave', () => {
      this.hide()
    })
  }

  show() {
    this.textTarget.classList.add('show')
  }

  hide() {
    this.textTarget.classList.remove('show')
  }
}
