import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['userSelect']

  initialize() {
    this.initSelect2()
    this.addListener()

    const user_id = this.userSelectTarget.value;
    if (user_id) this.fetchUser(user_id, this.selectUser.bind(this));
  }

  initSelect2() {
    $(this.userSelectTarget).select2({
      allowClear: true,
      placeholder: 'prenom.nom@example.com',
      ajax: {
        url: '/admin/clients',
        dataType: 'json',
        delay: 250,
        data: (params) => {
          return {
            "q[first_name_unaccent_or_last_name_unaccent_or_email_cont]": params.term
          };
        },
        processResults: (data) => {
          const results = $.map(data, (item) => {
            const full_name = (item.first_name || item.last_name) ? [item.first_name, item.last_name].join(' ') : null
            const text = [full_name, item.email].filter(n => n).join(' - ')
            return { "id": item.id, "text": text }
          });
          return { "results": results }
        }
      }
    })
  }

  addListener() {
    $(this.userSelectTarget)
      .on('select2:select', (e) => this.fetchUser(e.params.data.id, this.selectUser.bind(this)))
      .on('select2:unselect', () => this.unselectUser())
  }

  selectUser(user) {
    this.fillField('email', user.email, true);
    this.fillField('first_name', user.first_name, true);
    this.fillField('last_name', user.last_name, true);
    this.fillField('phone_number', user.phone_number, true);
    this.fillField('phone_country_code', user.phone_country_code, true);

    if (user.civility) {
      document.querySelector('input[name*="[civility]"][value="' + user.civility + '"]').checked = true
    } else {
      document.querySelectorAll('input[name*="[civility]"]').forEach((e) => e.checked = false)
    }
    document.querySelectorAll('input[name*="[civility]"]').forEach((e) => {
      e.disabled = true
      e.readOnly = true
    });

    document.getElementById('client-no-match').classList.add('vesta-display-none');
  }

  unselectUser() {
    this.fillField('email');
    this.fillField('first_name');
    this.fillField('last_name');
    this.fillField('phone_number');
    this.fillField('phone_country_code', 'FR', false);

    document.querySelectorAll('input[name*="[civility]"]').forEach((e) => {
      e.checked = false
      e.disabled = false
      e.readOnly = false
    });

    document.getElementById('client-no-match').classList.remove('vesta-display-none');
    document.getElementById('client-data-fields').classList.add('vesta-display-none');
  }

  fillField(field, value = null, disable = !!value) {
    let input = document.getElementById('sales_user_user_attributes_' + field);
    if (!input) {
      input = document.getElementById('offers_user_user_attributes_' + field);
    }

    input.disabled = disable;
    input.readOnly = disable;
    input.value = value;
    $(input).trigger('change');
  }

  fetchUser(id, operation) {
    fetch('/admin/clients/' + id, {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }).then(this.handle_response_errors)
      .then((response) => {
        document.getElementById('client-data-fields').classList.remove('vesta-display-none');
        return response.json().then(operation)
      })
      .catch((e) => {
        alertMessage('Une erreur est survenue', 'error');
      })
  }

  handle_response_errors(response) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

  showClientFields = (e) => {
    document.getElementById('client-data-fields').classList.remove('vesta-display-none');
    e.target.classList.add('vesta-display-none');
  };
}
