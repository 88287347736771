import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs';
export default class extends Controller {
  static values = { group: String }

  connect() {
    Sortable.create(this.element, {
      onEnd: this.onEnd.bind(this),
      group: this.groupValue,
    })
  }

  onEnd(event) {
    const sortableUpdateUrl = event.item.dataset.sortableUpdateUrl
    fetch(sortableUpdateUrl, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({ row_order_position: event.newIndex })
    })
  }
}
