import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'btnSlot',
    'nextLink',
    'nextLinkMobile',
    'nextBtn',
    'nextBtnMobile',
    'selectedDateSlot',
    'mobileSlides',
    'deskSlides',
    'chevronNextWeek',
    'chevronPrevWeek',
    'contactForm',
    'confirmationPage',
    'bottomContainer',
    'moreSlotsCta',
    'stickyContainer',
    'form',
    'email',
    'password',
    'modalIdentifier',
    'passwordConfirmationContainer',
    'forgotPasswordLink',
    'passwordLabel',
    'passwordLengthLabelContainer',
    'closeModalRedirection',
    'subtitle',
    'loader',
    'content',
    'submit'
  ];

  static values = {
    uniqueHash: String,
    slotsCount: Number,
    visit: Object,
    user: Object,
    currentUser: Boolean,
    currentUserIsHosmanian: Boolean
  }

  connect() {
    this.handleGa4Tracking();
  }

  initialize() {
    if (this.hasNextLinkTarget) {
      this.originalNextUrl = atob(this.nextLinkTarget.dataset.encode);
    }
    this.btnSlotTargets.forEach((btn) => {
      btn.setAttribute('data-action', 'click->application--booking#handleBtnSlot');
    });
  }
  // pass slot to next url in btn
  handleBtnSlot = (e) => {
    const btn = e.currentTarget;
    // deactivate all slots
    [...this.btnSlotTargets].forEach((btn) => {
      btn.classList.remove('active');
    });
    // activate chosen slot
    btn.classList.add('active');
    // check if admin url or not to handle first url param with & or ? (because using wizard in admin and not public)
    const adminUrl = this.originalNextUrl.includes('admin')
    // add chosen slot datetime to next page url
    this.nextLinkTarget.href = `${this.originalNextUrl}${adminUrl ? '&' : '?'}slot=${encodeURIComponent(btn.dataset.slot)}&slot_type=${btn.dataset.type}&source=${this.nextLinkTarget.dataset.source}&shared_by_user=${this.nextLinkTarget.dataset.sharedByUser}&lead_id=${this.nextLinkTarget.dataset.lead}`;
    // enable or display button to next page (according to device)
    if (this.hasStickyContainerTarget) {
      this.stickyContainerTarget.classList.remove('vesta-display-none');
      setTimeout(() => {
        this.stickyContainerTarget.classList.remove('vesta-opacity-0');
        this.stickyContainerTarget.classList.add('vesta-opacity-1');
      }, 100);
    } else {
      this.nextLinkTarget.classList.remove('vesta-button-disabled');
    }

    this.selectedDateSlotTargets.forEach((dateSlot) => (dateSlot.innerText = this.localDateTime(Date.parse(btn.dataset.slot))));

    // Update day slots style if time slots selected in other day column
    let activeDaySlot = document.querySelector('.vesta-booking-day-slot.active');
    // return if in admin view
    if (!activeDaySlot) return;
    // Day slot value from time slot
    const daySlotValue = e.currentTarget.dataset.day;

    if (activeDaySlot.dataset.current_date === daySlotValue) return;

    activeDaySlot.classList.remove('active');
    const daySlot = document.querySelector(`[data-current_date="${daySlotValue}"].vesta-booking-day-slot`);
    daySlot.classList.add('active');
  };

  showMoreSlots = () => {
    const hiddenSlots = document.querySelectorAll('.vesta-booking-time-container.vesta-display-none');
    hiddenSlots.forEach((slot) => {
      slot.classList.remove('vesta-display-none');
      setTimeout(() => {
        slot.classList.add('vesta-opacity-1');
      }, 100);
    });

    if (this.hasBottomContainerTarget) {
      this.bottomContainerTarget.classList.add('vesta-sticky-container');
      this.bottomContainerTarget.classList.remove('vesta-position-relative');
    }

    if (this.hasMoreSlotsCtaTarget) {
      this.moreSlotsCtaTarget.classList.add('vesta-display-none');
    }
  }

  localDateTime(timeInt) {
    let event = new Date(timeInt);
    let options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    };

    let strResult = event.toLocaleDateString('fr-FR', options);

    return strResult.charAt(0).toUpperCase() + strResult.slice(1);
  }

  manageSlots = (e) => {
    [...this.mobileSlidesTargets].forEach((slide) => {
      slide.classList.add('hidden');
      [...document.querySelectorAll('li')].forEach((li_element) => {
        if (li_element.dataset.current_date == e.currentTarget.dataset.date) {
          [...li_element.getElementsByClassName('hidden')].forEach((hidden_el) => {
            hidden_el.classList.remove('hidden');
          });
        }
      });
    });
  };

  managePrevWeek = () => {
    this.deskSlidesTargets.slice(0, 7).forEach((day) => {
      day.classList.remove('hidden-desk');
    });
    this.deskSlidesTargets.slice(7).forEach((day) => {
      day.classList.add('hidden-desk');
    });
    this.chevronPrevWeekTarget.classList.add('hidden');
    this.chevronNextWeekTarget.classList.remove('hidden');
  };

  manageNextWeek = () => {
    this.deskSlidesTargets.slice(0, 7).forEach((day) => {
      day.classList.add('hidden-desk');
    });
    this.deskSlidesTargets.slice(7).forEach((day) => {
      day.classList.remove('hidden-desk');
    });
    this.chevronPrevWeekTarget.classList.remove('hidden');
    this.chevronNextWeekTarget.classList.add('hidden');
  };

  submitVisit(event) {
    event.preventDefault();
    this.modalController = this.application.getControllerForElementAndIdentifier(this.modalIdentifierTarget, 'application--modal');
    if (this.modalController) this.modalController.show();
    const formData = new FormData(this.formTarget);
    this.createVisit(formData);
  }

  createVisit = async (formData) => {
    try {
      const response = await fetch(this.formTarget.action, {
        method: this.formTarget.method,
        headers: {
          'X-CSRF-Token': document.getElementsByName('csrf-token')[0].getAttribute('content'),
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        credentials: 'same-origin',
        body: JSON.stringify(this.formatVisitCreationParams(formData))
      });

      const data = await response.json();

      if (data.error) {
        this.handleError(data.error);
        return;
      }

      if (this.currentUserIsHosmanianValue) this.redirectForHosmanians();
      else if (this.currentUserValue) this.redirectToDashboard();
      else if (this.modalIdentifierTarget && !this.currentUserValue) this.updateModalWithFormData(data);
    } catch (error) {
      this.handleError(error);
    }
  }

  handleError = (error) => {
    if (this.modalController) this.modalController.hide();
    if (document.getElementsByClassName('iziToast-capsule').length < 1) alertMessage(error, 'error');
    this.submitTarget.disabled = false;
  }

  updateModalWithFormData(data) {
    if (this.hasLoaderTarget && this.hasContentTarget) {
      this.loaderTarget.classList.add('vesta-fade-out');
      // Wait for fade out to complete before hiding loader and showing content
      setTimeout(() => {
        this.loaderTarget.classList.add('vesta-display-none');
        this.loaderTarget.classList.remove('vesta-flex');
        this.loaderTarget.classList.remove('vesta-fade-out');

        // Display content but keep it invisible
        this.contentTarget.classList.remove('vesta-display-none');
        this.contentTarget.style.opacity = '0';
        this.closeModalRedirectionTarget.classList.remove('vesta-display-none');

        // and add fade in to show content
        setTimeout(() => {
          this.contentTarget.classList.add('vesta-fade-in');
          this.contentTarget.style.opacity = '';
        }, 50);
      }, 300);

      if (this.hasEmailTarget && data.user.email) this.emailTarget.value = data.user.email;
      if (this.hasPasswordConfirmationContainerTarget && !data.user.last_sign_in_at) this.passwordConfirmationContainerTarget.classList.remove('vesta-display-none');
      if (this.hasForgotPasswordLinkTarget && data.user.last_sign_in_at) this.forgotPasswordLinkTarget.classList.remove('vesta-display-none');
      if (this.hasPasswordLabelTarget && data.user.last_sign_in_at) this.passwordLabelTarget.innerText = 'Mot de passe';
      if (this.hasPasswordLengthLabelContainerTarget && data.user.last_sign_in_at) this.passwordLengthLabelContainerTarget.classList.add('vesta-display-none');
      if (this.hasCloseModalRedirectionTarget) this.closeModalRedirectionTarget.href = data.success_redirection_url || '';
      if (this.hasSubtitleTarget && data.user.last_sign_in_at) this.subtitleTarget.innerText = `${data.user.civility == 'mr' ? 'Monsieur' : 'Madame'} ${data.user.last_name}, on se connaît déjà ! Connectez-vous à votre espace Hosman pour suivre votre projet d'achat.`;
    }
  }

  submitSession(event) {
    event.preventDefault();
    const email = this.emailTarget.value;
    const password = this.passwordTarget.value;
    this.createSession(email, password);
  }

  createSession = async (email, password) => {
    try {
      const response = await fetch(`${window.location.origin}/api/v1/sessions`, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': document.getElementsByName('csrf-token')[0].getAttribute('content'),
          'Content-Type': 'application/json'
        },
        credentials: 'same-origin',
        body: JSON.stringify({ user: { email, password } })
      });

      const data = await response.json();

      if (data.sign_in) this.redirectToDashboard();
      else {
        this.passwordTarget.value = '';
        this.handleError(data.error);
      }
    } catch (error) {
      this.handleError(error);
    }
  }

  formatVisitCreationParams = (formData) => {
    const object = Object.fromEntries(formData.entries());

    return {
      user: {
        civility: object['user[civility]'],
        first_name: object['user[first_name]'],
        last_name: object['user[last_name]'],
        email: object['user[email]'],
        phone_number: object['user[phone_number]'],
        phone_country_code: object['user[phone_country_code]'],
        situation: object['user[situation]'],
        project_state: object['user[project_state]'],
        visits_attributes: {
          '0': {
            start_time: object['user[visits_attributes][0][start_time]'],
            slot_type: object['user[visits_attributes][0][slot_type]'],
            fame_reason: object['user[visits_attributes][0][fame_reason]'],
            source: object['user[visits_attributes][0][source]'],
            alert_opt_in: object['user[visits_attributes][0][alert_opt_in]'] === 'on',
            shared_by_user: object['user[visits_attributes][0][shared_by_user]'],
          }
        }
      }
    }
  }

  redirectToDashboard = () => {
    const protocol = window.location.protocol;
    window.location.href = `${protocol}//app.${window.location.host.replace('www.', '')}/ea`;
  }

  redirectForHosmanians = () => {
    alertMessage('La visite à bien été prise', 'success')
    setTimeout(() => {
      window.location.href = `${window.location.origin}/admin/customer-services/buyers`;
    }, 2000);
  }

  ga4Event = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'click_guest_visit',
    })
  }

  handleGa4Tracking = () => {
    if(this.slotsCountValue) {
      // new GA4 tracking plan
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'view_visit_slots',
        'slotsCount': this.slotsCountValue,
        'unique_hash': this.uniqueHashValue
      })
    }

    if (this.hasContactFormTarget) {
      // new GA4 tracking plan
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'view_visit_contact',
        'unique_hash': this.uniqueHashValue
      })
    }

    if (this.hasConfirmationPageTarget) {
      // new GA4 tracking plan
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'book_visit',
        'visit_date': this.visitValue.start_time,
        'slot_type': this.visitValue.slot_type,
        'user_id': this.visitValue.user_id,
        'user_email': this.userValue.email,
        'user_phone': this.userValue.phone_number,
        'user_first_name': this.userValue.first_name,
        'user_last_name': this.userValue.last_name,
        'unique_hash': this.uniqueHashValue
      })
    }
  }
}
